import React from "react";

const InputText = ({ placeholder, name }) => {
  return (
    <input
      type="text"
      name={name}
      placeholder={`${placeholder || "Im an input text"}`}
    />
  );
};

export default InputText;
