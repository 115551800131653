import React from "react";
import RichText from "components/atoms/RichText/RichText";

const MainTitle = ({ title, description }) => {
  return (
    <div className="main-title slice">
      {title && (
        <h1 className="main-title__title">
          <RichText type="text" content={title} />
        </h1>
      )}
      {description && (
        <div className="main-title__description">
          <RichText content={description} />
        </div>
      )}
    </div>
  );
};

export default MainTitle;
