import React from "react";
import { InputText } from "components/atoms/Form/Input";

const ContactForm = ({ sendContact, contactFormRef }) => {
  return (
    <form ref={contactFormRef}>
      <InputText name="name" placeholder="Nom Prénom *" />
      <InputText name="phone" placeholder="N° de téléphone (recommandé)" />
      <InputText name="email" placeholder="Email *" />
      <InputText name="subject" placeholder="Objet" />
      <textarea
        className="input-text textarea required"
        name="message"
        placeholder="Message"
      ></textarea>
      <button className="btn btn-primary" onClick={sendContact}>
        ENVOYER
      </button>
    </form>
  );
};

export default ContactForm;
