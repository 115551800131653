import React from "react";
import RichText from "components/atoms/RichText/RichText";
import ContactForm from "components/organisms/ContactForm";
import MainTitle from "components/molecules/MainTitle/MainTitle";
import GeneralHelmet from "components/molecules/GeneralHelmet/GeneralHelmet";
import contactDataService from "services/contact";
import AlertMsg from "components/atoms/AlertMsg/AlertMsg";

const Contact = ({ data }) => {
  const { content, meta } = data;
  const contactFormRef = React.useRef(null);
  const [alertMessage, setAlertMessage] = React.useState(null);
  const [alertAppareance, setAlertAppareance] = React.useState(null);
  const [sending, setSending] = React.useState(false);

  const sendContact = (e) => {
    e.preventDefault();
    const form = contactFormRef.current;
    const data = new FormData(form);
    setSending(true);
    contactDataService
      .sendContact(data)
      .then((res) => {
        setAlertMessage("Votre message a été envoyé avec succès. :)");
        setAlertAppareance("success");
        form.reset();
        setSending(false);
      })
      .catch((err) => {
        setAlertMessage("Une erreur s'est produite lors de l'envoi. :(");
        setAlertAppareance("danger");
        setSending(false);
      });
  };

  return (
    <>
      <GeneralHelmet meta={meta} />
      <div className="page-content">
        <div className="contact">
          <div className="contact__header">
            <MainTitle
              title={content.title}
              description={content.description}
            />
          </div>
          {alertMessage && (
            <AlertMsg message={alertMessage} appareance={alertAppareance} />
          )}
          <div className="contact__container">
            <div className="contact__form">
              <ContactForm
                contactFormRef={contactFormRef}
                sendContact={sendContact}
              />
              {sending && "Envoi en cours..."}
            </div>
            <div className="contact__info rich-text">
              <RichText content={content.infoContact} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
